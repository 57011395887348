import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Button, Modal } from 'components';

interface ConfirmDeleteContractProps {
  modalTitle?: React.ReactNode;
  open: boolean;
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmDeleteContract: React.FC<ConfirmDeleteContractProps> = ({
  onSubmit,
  onClose,
  open,
  className,
  children,
  ...props
}) => {
  return (
    <Modal
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      renderContainer={StyledConfirmDeleteContract}
      {...props}>
      {children}
      <Button onClick={onClose} large className="cancel-btn">
        Cancel
      </Button>
      <Button onClick={onSubmit} large>
        Delete
      </Button>
    </Modal>
  );
};

export default ConfirmDeleteContract;

const StyledConfirmDeleteContract = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0;
    }
  }

  .modal-content {
    width: 300px;
    max-width: 300px;
    font-size: 16px;
    line-height: 24px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  .close-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    border-radius: ${props => props.theme.misc.borderRadius};

    svg {
      width: 8px;
      height: 8px;
    }
  }

  button.large {
    width: 100%;
    margin-top: 16px;

    &.cancel-btn {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.pink};
    }
  }
`;
