import React from 'react';

import styled from '@emotion/styled';
import noPhoto from 'assets/images/avatar.jpg';

const Avatar = props => <StyledAvatar {...props} />;

export default React.memo(Avatar);

const StyledAvatar = styled.div<{ src?: string }>`
  width: 24px;
  height: 24px;
  border-radius: ${props => props.theme.misc.borderRadius};
  background-image: url(${props => props.src || noPhoto});
  background-size: cover;
  flex-shrink: 0;
`;
