import React, { useState, useContext, Dispatch, SetStateAction, createContext } from 'react';

import notify from 'notify';

import { DropdownOption } from 'components/Dropdown';
import { tryGetFirstError } from 'utils/requests';
import UserOption from '../UserOption';
import UserManagementContext from '../UserManagementContext';
import { createTeam, deleteTeam, promoteTeamMember, editTeamMember } from '../api';
import { UserRoleTypes, TeamMember } from '../usermanagement.d';

interface TeamManagerValues {
  openTeamMemberModal?: number;
  setOpenTeamMemberModal: Dispatch<SetStateAction<number | undefined>>;
  addTeamMember: (value: TeamMember) => void;
  removeTeamMember: (value: number) => void;
  addTeam: (value: { id: number; members: number[] }) => void;
  removeTeam: (value: number) => void;
  getUserOptions: (value: TeamMember[]) => DropdownOption[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  deleteTeamId: number | null;
  setDeleteTeamId: Dispatch<SetStateAction<number | null>>;
  deleteTeamMember: number | null;
  setDeleteTeamMember: Dispatch<SetStateAction<number | null>>;
}

const TeamManager = createContext({} as TeamManagerValues);

export const TeamManagerProvider = ({ children }) => {
  const { users, reload } = useContext(UserManagementContext);
  const [search, setSearch] = useState<string>('');
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState<number | undefined>();
  const [deleteTeamId, setDeleteTeamId] = useState<number | null>(null);
  const [deleteTeamMember, setDeleteTeamMember] = useState<number | null>(null);

  const getUserOptions = (data: TeamMember[]) =>
    data.map((user: TeamMember) => ({
      text: user.name || user.email,
      value: user.id,
      label: <UserOption data={user} />
    }));

  const addTeam = async (payload: { id: number; members: number[] }) => {
    try {
      const { id, members } = payload;
      const teamLead = users.find(user => user.id === id);
      const team = await createTeam({
        name: `${teamLead?.name || teamLead?.email}'s Team`,
        members: teamLead ? [...members, teamLead.id] : members
      });
      await promoteTeamMember({
        email: teamLead?.email,
        roleId: UserRoleTypes.teamLead,
        teamId: team.id
      });
      notify('Team created successfully.');
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const removeTeam = async (teamId: number) => {
    try {
      await deleteTeam(teamId);
      setDeleteTeamId(null);
      notify('Team removed successfully.');
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const addTeamMember = async (user: TeamMember) => {
    try {
      const { teamId, email } = user;
      const payload = {
        teamId,
        email
      };
      await editTeamMember(payload);
      notify('Team member added successfully.');
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const removeTeamMember = async (userId: number) => {
    try {
      const user = users.find(user => user.id === userId);
      const payload = {
        teamId: null,
        email: user?.email
      };
      await editTeamMember(payload);
      setDeleteTeamMember(null);
      notify('Team member removed successfully.');
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const values: TeamManagerValues = {
    openTeamMemberModal,
    setOpenTeamMemberModal,
    addTeamMember,
    removeTeamMember,
    addTeam,
    removeTeam,
    getUserOptions,
    search,
    setSearch,
    deleteTeamId,
    setDeleteTeamId,
    deleteTeamMember,
    setDeleteTeamMember
  };

  return <TeamManager.Provider value={values}>{children}</TeamManager.Provider>;
};

export default TeamManager;
