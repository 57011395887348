import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import { SITE_URL } from 'consts';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Avatar from './Avatar';
import UserDetails from './UserDetails';
import { UserRoles, TeamMember, UserProject } from './usermanagement.d';

interface UsersListItemProps {
  data: TeamMember;
  onEdit: () => void;
  onOpen: () => void;
  onSeeMoreClick: () => void;
  userProjects?: UserProject[];
}

const UsersListItem: React.FC<UsersListItemProps> = ({
  data,
  onEdit,
  onOpen,
  userProjects,
  onSeeMoreClick
}) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const { avatar, name, email, team, activeTransactions, closedTransactions, role } = data;

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // onOpen?.();
    onEdit();
  };

  useEffect(() => {
    if (openDetails) onOpen?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetails]);

  return (
    <>
      <StyledUsersListItem
        className={clsx('users-list__item', { 'users-list__item__open': openDetails })}
        onClick={() => setOpenDetails(v => !v)}>
        <div className="user-info users-list__item-name">
          <Icon name="plus-mini" fill="#000000" />
          <a
            href={
              SITE_URL && data.showOnOurTeam
                ? `${SITE_URL}/our-team/member?id=${data.id}`
                : undefined
            }
            target="_blank"
            rel="noreferrer"
            title={data.name}
            className="users-list__link">
            <Avatar src={avatar} className="user-img" />
            {name || email}
          </a>
          {role !== 'user' && <span className="badge">{UserRoles[role]}</span>}
        </div>
        <div className="user-info users-list__item-active">{activeTransactions || '-'}</div>
        <div className="user-info users-list__item-closed">{closedTransactions || '-'}</div>
        <div className="user-info users-list__item-team">
          <span>{team?.name}</span>
        </div>
        <div className="user-info users-list__item-settings">
          <Button simple onClick={handleEditClick}>
            <Icon name="gear" />
          </Button>
        </div>
      </StyledUsersListItem>
      {openDetails && (
        <UserDetails data={data} onSeeMoreClick={onSeeMoreClick} userProjects={userProjects} />
      )}
    </>
  );
};

export default React.memo(UsersListItem);

const StyledUsersListItem = styled.div`
  display: flex;
  .icon {
    flex-shrink: 0;
    transition: all 300ms;
  }
  &.users-list__item__open {
    .icon-plus-mini {
      transform: rotate(45deg);
      fill: ${props => props.theme.colors.red};
    }
  }
  .users-list__item-settings {
    .button {
      width: 24px;
      height: 24px;
    }
  }
  .users-list__link {
    display: contents;
    color: inherit;
    text-decoration: none;
  }
  .user-img {
    margin: 0 12px 0 15px;
  }
  .badge {
    background: ${props => props.theme.colors.seashell};
    color: ${props => props.theme.colors.red};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 2px 4px;
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: 16px;
  }
`;
