import React, { useContext, useState } from 'react';

import ConfirmationDialog from 'components/ConfirmationDialog';
import ManageTeamsModal from './ManageTeamsModal';
import AddTeamMemberModal from './AddTeamMemberModal';
import CreateTeamModal from './CreateTeamModal';
import Context, { TeamManagerProvider } from './state';

interface ManageTeamsProps {
  open: boolean;
  onClose: () => void;
}

const ManageTeams: React.FC<ManageTeamsProps> = props => {
  const {
    openTeamMemberModal,
    setOpenTeamMemberModal,
    removeTeam,
    deleteTeamId,
    setDeleteTeamId,
    deleteTeamMember,
    setDeleteTeamMember,
    removeTeamMember
  } = useContext(Context);
  const [openTeamModal, setOpenTeamModal] = useState<boolean>(false);

  return (
    <>
      <ManageTeamsModal setOpenTeamModal={setOpenTeamModal} {...props} />
      <CreateTeamModal open={openTeamModal} onClose={() => setOpenTeamModal(false)} />
      <AddTeamMemberModal
        open={Boolean(openTeamMemberModal)}
        onClose={() => setOpenTeamMemberModal(undefined)}
      />
      <ConfirmationDialog
        modalTitle="Remove Team"
        open={Boolean(deleteTeamId)}
        onSubmit={() => (deleteTeamId ? removeTeam(deleteTeamId) : null)}
        onClose={() => setDeleteTeamId(null)}>
        <p>Are you sure you want to remove this team? This action can’t be undone.</p>
      </ConfirmationDialog>
      <ConfirmationDialog
        modalTitle="Remove Member"
        open={Boolean(deleteTeamMember)}
        onSubmit={() => (deleteTeamMember ? removeTeamMember(deleteTeamMember) : null)}
        onClose={() => setDeleteTeamMember(null)}>
        <p>Are you sure you want to remove this member? This action can’t be undone.</p>
      </ConfirmationDialog>
    </>
  );
};

export default (props: ManageTeamsProps) => (
  <TeamManagerProvider>
    <ManageTeams {...props} />
  </TeamManagerProvider>
);
