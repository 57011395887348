import React, { useState, useEffect, useMemo, Dispatch } from 'react';
import {
  DecodedValueMap,
  NumberParam,
  QueryParamConfig,
  SetQuery,
  StringParam,
  useQueryParams
} from 'use-query-params';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components';
import {
  getUsersDetailed,
  getTeams as getTeamsRequest,
  getUserProjects as getUserListingsRequest
} from './api';
import { Team, TeamMember, UserProject } from './usermanagement.d';

type Filters = {
  team: QueryParamConfig<number, number>;
  search: QueryParamConfig<string, string>;
};

interface UserManagementValues {
  users: TeamMember[];
  teams?: Team[];
  filters: DecodedValueMap<Filters>;
  teamOptions: DropdownOption[];
  setFilters: SetQuery<Filters>;
  userModalData?: TeamMember;
  setUserModalData: Dispatch<React.SetStateAction<TeamMember | undefined>>;
  getUserProjects: (value: number) => void;
  userProjects?: Record<number, UserProject[]>;
  reload: () => void;
}

const UserManagementContext = React.createContext({} as UserManagementValues);

export const UserManagementProvider = ({ children }) => {
  const [users, setUsers] = useState<TeamMember[]>([]);
  const [teams, setTeams] = useState<Team[]>();
  const [filters, setFilters] = useQueryParams({
    team: NumberParam as QueryParamConfig<number>,
    search: StringParam as QueryParamConfig<string>
  });
  const [userModalData, setUserModalData] = useState<TeamMember | undefined>();
  const [userProjects, setUserProjects] = useState<Record<number, UserProject[]>>({});

  const teamOptions = useMemo(
    () =>
      teams?.map((team: Team) => ({
        text: team.name,
        value: team.id
      })) || [],
    [teams]
  );

  const getUsers = async () => {
    try {
      const users = await getUsersDetailed();
      setUsers(users);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const getTeams = async () => {
    try {
      const teams = await getTeamsRequest();
      setTeams(teams);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const getUserProjects = async (userId: number) => {
    try {
      const userProjects = await getUserListingsRequest(userId);
      setUserProjects(v => ({ ...v, [userId]: userProjects }));
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const reload = () => {
    getUsers();
    getTeams();
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (!teams) return;
    const teamIdx = teams.findIndex(team => team.id === filters.team);
    if (teamIdx < 0) setFilters({ team: undefined }, 'replaceIn');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.team, teams]);

  const values: UserManagementValues = {
    users,
    teams,
    filters,
    teamOptions,
    setFilters,
    userModalData,
    setUserModalData,
    userProjects,
    getUserProjects,
    reload
  };

  return <UserManagementContext.Provider value={values}>{children}</UserManagementContext.Provider>;
};

export default UserManagementContext;
