import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, Icon } from 'components';
import { MemberCommissionsForm } from 'components/forms';
import ConfirmDeleteContract from './ConfirmDeleteContract';
import { deleteDocument } from 'api/documents';
import { getUserProfileData, updateUserProfileData } from '../api';
import Context from '../UserManagementContext';

const ContractsTab = () => {
  const { userModalData, setUserModalData } = useContext(Context);
  const [contracts, setContracts] = useState<EmploymentContract[]>([]);
  const [commissionsData, setCommissionsData] = useState<Partial<CommissionsData>>();
  const [openUploadForm, setOpenUploadForm] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<number | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const memberId = userModalData?.id;

  const getContracts = useCallback(async () => {
    if (!memberId) return;
    try {
      const { contracts, user, commissions } = await getUserProfileData(memberId);
      setContracts(contracts);
      setUserModalData(user);
      setCommissionsData(commissions);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [memberId]);

  const updateUserContract = async values => {
    try {
      const { commissions } = await updateUserProfileData({ id: memberId!, ...values });
      setCommissionsData(commissions);
      notify('Commision distribution was updated succefully');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const submitContractRemoval = async () => {
    if (!openConfirmDelete || !userModalData) return;
    try {
      await deleteDocument(openConfirmDelete);
      const { contracts } = await getUserProfileData(memberId!);
      setContracts(contracts);
      setOpenConfirmDelete(undefined);
      notify('Contract was removed succefully');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const uploadUserContract = async values => {
    try {
      const { commissions, contracts } = await updateUserProfileData({ id: memberId!, ...values });
      setCommissionsData(commissions);
      setContracts(contracts);
      notify('Contract uploaded');
      setOpenUploadForm(false);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    (async () => {
      await getContracts();
      setLoaded(true);
    })();
  }, []);

  const getContent = () => {
    if (openUploadForm) {
      return (
        <>
          <h3>
            {openUploadForm && (
              <Button secondary className="back-btn" onClick={() => setOpenUploadForm(false)}>
                <Icon name="arrow-simple" />
              </Button>
            )}
            Upload Contract
            </h3>
          <MemberCommissionsForm
            data={commissionsData}
            userId={memberId!}
            onSubmit={uploadUserContract}
            extended
          />
        </>
      );
    }
    if (!contracts.length && loaded) {
      return (
        <>
          <h3>Upload Contract</h3>
          <MemberCommissionsForm
            data={commissionsData}
            userId={memberId!}
            onSubmit={uploadUserContract}
            extended
          />
        </>
      );
    }

    return (
      <>
        <div className="contracts__block">
          <div className="contracts__header">
            Contracts
            <Button onClick={() => setOpenUploadForm(true)} className="upload-btn">
              Upload
            </Button>
          </div>
          {contracts.map(contract => (
            <div className="contracts__item" key={contract.id}>
              <div className="contracts__item-info contracts__item-name">
                <a href={contract.link} target="_blank" title={contract.filename}>
                  {contract.filename}
                </a>
              </div>
              <div className="contracts__item-info">
                {contract.createdOn ? dayjs.unix(contract.createdOn).format('MM/DD/YYYY') : ''}
              </div>
              {contract?.id && (
                <div className="menu-buttons">
                  <Button simple title="Delete" onClick={() => setOpenConfirmDelete(contract.id)}>
                    <Icon name="trash" />
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="contracts__block">
          <div className="contracts__header">Commission distribution</div>
          {commissionsData && (
            <MemberCommissionsForm
              data={commissionsData}
              userId={memberId!}
              onSubmit={updateUserContract}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <StyledContractsTab className="contracts scrollable-fct">
        {getContent()}
      </StyledContractsTab>
      {
        openConfirmDelete && (
          <ConfirmDeleteContract
            modalTitle={`Delete contract`}
            open={Boolean(openConfirmDelete)}
            onSubmit={submitContractRemoval}
            onClose={() => setOpenConfirmDelete(undefined)}>
            <p>{`This contract will be removed completely from the system.`}</p>
          </ConfirmDeleteContract>
        )
      }
    </>
  );
};

export default React.memo(ContractsTab);

const StyledContractsTab = styled.div`
  margin: 25px 0 0;
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 24px;
  }
  .back-btn {
    margin-right: 14px;
    display: inline-flex;
    align-items: center;
  }
  .contracts {
    &__block {
      margin: 0 0 30px;
    }
    &__header {
      padding: 25px 0 10px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.grayDark};
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 14px 0;
      color: ${props => props.theme.colors.grayDark};
      &-info {
        flex: 1;
        &:last-child {
          flex: 0.75;
        }
      }
      &-name {
        font-weight: 500;
        color: #000;
      }
      & ~ .contracts__item {
        border-top: 1px solid ${props => props.theme.colors.seashell};
      }
    }
  }
  .no-result {
    padding: 16px 0;
    color: ${props => props.theme.colors.grayDark};
    font-weight: 500;
    .button {
      display: block;
      margin-top: 20px;
    }
  }
  .upload-btn {
    margin-left: auto;
  }
  .menu-buttons {
    display: flex;
    justify-content: flex-end;

    .button,
    a {
      margin: 0 8px;
      .icon {
        fill: ${props => props.theme.colors.graphite};
      }
    }
  }
  .commissions-form {
    margin: 25px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .contracts {
      padding: 0 4px 0 0;
      max-height: calc(100vh - 288px);
      overflow: auto;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .contracts {
      padding: 0;
      &__header {
        padding: 0 0 10px;
        & > *:not(:first-of-type) {
          display: none;
        }
      }
      &__block {
        margin: 25px 0 30px;
      }
    }
  }
`;
