/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect, useMemo } from 'react';

import styled from '@emotion/styled';
import notify from 'notify';
import useLayout from 'hooks/useLayout';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  SideMenu,
  SideMenuProps,
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Modal
} from '@xchange/uikit';
import Tabs from 'components/Tabs';
import { SITE_URL } from 'consts';
import { tryGetFirstError } from 'utils/requests';
import noPhoto from 'assets/images/avatar.jpg';
import Context from '../UserManagementContext';
import { AgentTabKeys, UserRoleTypes } from '../usermanagement.d';
import { promoteTeamMember, updateUserProfileData, deleteUser } from '../api';
import ContractsTab from './ContractsTab';
import ProjectsTab from './ProjectsTab';

type Rights = {
  admin: boolean;
  teamLead: boolean;
  active: boolean;
};

const tabs = [
  { key: 'projects', text: 'Recent Projects' },
  { key: 'contracts', text: 'Employment Contracts' }
];

interface ManageUserProps extends SideMenuProps {
  defaultTab?: AgentTabKeys;
}

const ManageUser: React.FC<ManageUserProps> = ({ defaultTab, onClose, ...props }) => {
  const layout = useLayout();
  const { teamOptions, reload, userModalData: data, userProjects, setUserModalData } = useContext(
    Context
  );
  const [team, setTeam] = useState<number | null>();
  const [position, setPosition] = useState<string | null>();
  const [rights, setRights] = useState<Rights>({
    admin: false,
    teamLead: false,
    active: false
  });
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<AgentTabKeys>(AgentTabKeys.Projects);
  const isManagingBroker = data?.role === 'managing_broker';

  const tabContent = useMemo(() => {
    if (!data) return null;
    switch (activeTab) {
      case AgentTabKeys.Projects:
        return <ProjectsTab userProjects={userProjects?.[data.id]} />;
      case AgentTabKeys.Contracts:
        return <ContractsTab />;
      default:
        return null;
    }
  }, [activeTab, userProjects, data]);

  useEffect(() => {
    setRights({
      admin: data?.roleId === UserRoleTypes.admin,
      teamLead: data?.roleId === UserRoleTypes.teamLead,
      active: Boolean(data?.active)
    });
    setTeam(data?.teamId);
    setPosition(data?.position);
  }, [data]);

  useEffect(() => {
    if (defaultTab) setActiveTab(defaultTab);
  }, [defaultTab]);

  if (!data) return null;

  const { id, name, email, avatar } = data;

  const updateRights = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setRights({ ...rights, [name]: checked });
  };

  const onSubmit = async () => {
    try {
      const userRole = rights.admin
        ? UserRoleTypes.admin
        : rights.teamLead
          ? UserRoleTypes.teamLead
          : UserRoleTypes.user;
      const payload = {
        roleId: userRole,
        email: data.email,
        active: rights.active,
        teamId: team
      };
      await promoteTeamMember(payload);
      await updateUserProfileData({
        id: data.id,
        showOnOurTeam: data.showOnOurTeam,
        position: position || undefined
      });
      notify('User updated successfully.');
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    onClose?.();
  };

  const onDelete = async () => {
    try {
      await deleteUser(id);
      notify('User deleted successfully.');
      setOpenDeleteUser(false);
      onClose?.();
      reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const contentComponent = (
    <StyledManageUser className="manage-user">
      <div className="user-info">
        <a
          href={
            SITE_URL && data.showOnOurTeam ? `${SITE_URL}/our-team/member?id=${data.id}` : undefined
          }
          className="user-info__photo"
          style={{ backgroundImage: `url(${avatar || noPhoto})` }}
          title={data.name}
          target="_blank"
          rel="noreferrer"
        />
        <div className="user-info__details">
          <h3 className="user-info__title">
            <a
              href={
                SITE_URL && data.showOnOurTeam
                  ? `${SITE_URL}/our-team/member?id=${data.id}`
                  : undefined
              }
              title={data.name}
              target="_blank"
              rel="noreferrer">
              {name}
            </a>
          </h3>
          <a href={`mailto:${email}`} className="user-info__link">
            {email}
          </a>
          <Checkbox
            label="Show on “Our Team” page"
            checked={data.showOnOurTeam}
            name="showOnOurTeam"
            onChange={e => setUserModalData(v => ({ ...v!, showOnOurTeam: e.target.checked }))}
          />
          <Checkbox
            label="Admin Rights"
            checked={rights.admin || isManagingBroker}
            disabled={rights.teamLead || isManagingBroker}
            name="admin"
            onChange={updateRights}
          />
          <Checkbox
            label="Active Account"
            checked={rights.active}
            disabled={isManagingBroker}
            name="active"
            onChange={updateRights}
          />
        </div>
        <Button
          secondary
          className="delete-user-btn"
          onClick={() => setOpenDeleteUser(true)}
          disabled={isManagingBroker}>
          <Icon name="delete-user" />
          Delete this User
        </Button>
      </div>
      <div className="manage-team">
        <Dropdown
          label="Team"
          placeholder="Select"
          options={teamOptions}
          value={team}
          onChange={setTeam}
          disabled={isManagingBroker}
        />
        <Input
          value={position}
          name="position"
          label="Position"
          placeholder="Enter"
          onChange={e => setPosition(e.target.value)}
          disabled={isManagingBroker}
        />
        <Button className="save-btn" onClick={onSubmit}>
          Save
        </Button>
        <Checkbox
          label="Team Lead"
          checked={rights.teamLead}
          disabled={rights.admin || isManagingBroker}
          name="teamLead"
          onChange={updateRights}
        />
      </div>
      <StyledTabs
        activeTab={activeTab}
        onSelect={(_e, value) => setActiveTab(value.key)}
        items={tabs}
      />
      {tabContent}
    </StyledManageUser>
  );

  const getContent = () => {
    switch (layout) {
      case 'mobile':
        return (
          <StyledModal renderContainer={StyledModalContainer} {...props}>
            {contentComponent}
          </StyledModal>
        );
      default:
        return (
          <StyledSideMenu onClose={onClose} {...props}>
            {contentComponent}
          </StyledSideMenu>
        );
    }
  };

  return (
    <>
      {getContent()}
      <ConfirmationDialog
        modalTitle="Delete User"
        open={openDeleteUser}
        onSubmit={onDelete}
        onClose={() => setOpenDeleteUser(false)}>
        <p>Are you sure you want to remove this user? This action can’t be undone.</p>
      </ConfirmationDialog>
    </>
  );
};

export default React.memo(ManageUser);

const StyledSideMenu = styled(SideMenu)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledManageUser = styled.div`
  width: 728px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  .user-info {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;

    &__photo {
      margin: 0 16px 0 0;
      width: 124px;
      height: 124px;
      border-radius: ${props => props.theme.misc.borderRadius};
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
    }
    &__title {
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 24px;
    }
    &__link {
      margin-bottom: 8px;
      color: ${props => props.theme.colors.grayDark};
      text-decoration: none;
    }
    &__details {
      display: flex;
      flex-flow: column;
    }
    .checkbox {
      margin-top: 8px;
    }
    .delete-user-btn {
      margin-left: auto;
      .icon {
        margin-right: 4px;
        fill: ${props => props.theme.colors.red};
      }
      &.disabled .icon {
        fill: ${props => props.theme.colors.grayDark};
      }
    }
  }
  .manage-team {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 32px;
    .dropdown {
      min-width: 300px;

      & ~ * {
        margin-left: 16px;
      }
    }
    .input {
      min-width: 300px;
    }
    .button {
      min-width: 96px;
      height: 40px;
    }
    .checkbox {
      margin: 12px 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    .user-info {
      flex-flow: column;
      align-items: flex-start;
      &__photo {
        margin: 0 0 16px 0;
      }
      .delete-user-btn {
        margin: 16px 0 0;
      }
    }
    .manage-team {
      flex-flow: column;
      align-items: flex-start;
      .dropdown {
        width: 100%;
        margin: 8px 0;
        & ~ * {
          margin: 0;
          width: 100%;
        }
      }
      .save-btn {
        width: 100%;
        margin: 16px 0 10px;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  flex-wrap: nowrap;
  .tab-item {
    width: 100%;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
    min-width: unset;
    border-radius: 0;

    .dropdown {
      width: 100%;
      min-width: unset;
    }
  }
`;

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }
`;
