import React from 'react';
import { Router } from '@reach/router';
import UserManagementPage from 'views/UserManagementPage';
import PrivateRoute from 'components/PrivateRoute';
import Workspace from 'workspace';

const UserManagement = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/user-management" component={UserManagementPage} admin />
      </Router>
    </Workspace>
  );
};

export default UserManagement;
