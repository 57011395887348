import React from 'react';

import { SITE_URL } from 'consts';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Avatar from '../Avatar';
import { UserRoles, TeamMember as TeamMemberProp } from '../usermanagement.d';

interface TeamMemberProps {
  data: TeamMemberProp;
  onRemove: (value: number) => void;
}

const TeamMember: React.FC<TeamMemberProps> = ({ data, onRemove }) => {
  const { name, email, avatar, role } = data;

  return (
    <StyledTeamMember className="team-member">
      <span className="team-member__item team-member__name">
        <a
          href={SITE_URL && data.showOnOurTeam ? `${SITE_URL}/our-team/member?id=${data.id}` : undefined}
          title={data.name}
          target="_blank"
          rel="noreferrer"
          className="team-member__link">
          <Avatar src={avatar} className="team-member__name-img" />
          <span>{name || email}</span>
        </a>
        {role !== 'user' && <span className="badge">{UserRoles[role]}</span>}
      </span>
      <span className="team-member__item team-member__remove-button">
        <StyledButton className="remove-button" simple onClick={() => onRemove(data.id)}>
          ✗ Remove
        </StyledButton>
      </span>
    </StyledTeamMember>
  );
};

const StyledTeamMember = styled.div`
  display: flex;
  flex: 1;
  margin: 0 0 16px;
  .team-member {
    &__item {
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      display: flex;
    }
    &__link {
      display: contents;
      color: inherit;
      text-decoration: none;
    }
    &__name {
      flex: 2.5;
    }
    &__remove-button {
      flex: 2;
      margin-right: 81px;
    }
    &__name-img {
      margin: 0 12px 0 0;
      flex-shrink: 0;
    }
  }
  .badge {
    background: ${props => props.theme.colors.seashell};
    color: ${props => props.theme.colors.red};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 2px 4px;
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: 16px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .team-member {
      &__remove-button {
        margin-right: 0;
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
      &__name {
        flex: 4;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  &.simple {
    color: ${props => props.theme.colors.red};
    font-weight: normal;
  }
`;

export default TeamMember;
