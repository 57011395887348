import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { UserProject } from '../usermanagement.d';

interface ProjectsTabProps {
  userProjects?: UserProject[];
}

const ProjectsTab: React.FC<ProjectsTabProps> = ({ userProjects }) => {
  return (
    <StyledProjectsTab className="project scrollable-fct">
      <div className="project__header project__item">
        <div className="project__item-info project__item-name">Recent Project</div>
        <div className="project__item-info">Type</div>
        <div className="project__item-info">Status</div>
        <div className="project__item-info">Link</div>
      </div>
      {userProjects?.map(project => (
        <div className="project__item" key={project.id}>
          <div className="project__item-info project__item-name">{project.name}</div>
          <div className="project__item-info">{project.type}</div>
          <div
            className={clsx('project__item-info project__item-status', {
              active: project.status?.toLowerCase() === 'active'
            })}>
            {project.status}
          </div>
          <a
            className="project__item-info project__item-link"
            title={project.name}
            href={project.url}
            target="_blank"
            rel="noreferrer">
            View page →
          </a>
        </div>
      ))}
    </StyledProjectsTab>
  );
};

export default React.memo(ProjectsTab);

const StyledProjectsTab = styled.div`
  .project {
    &__header {
      color: ${props => props.theme.colors.grayDark};
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      &-info {
        flex: 1;
        &:last-child {
          flex: 0.75;
        }
      }
      &-name {
        flex: 2.5;
      }
      &-status.active {
        color: ${props => props.theme.colors.lightGreen};
      }
      &-link {
        color: ${props => props.theme.colors.red};
        font-weight: 500;
        text-decoration: none;
      }
      & ~ * {
        border-top: 1px solid ${props => props.theme.colors.seashell};
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .project {
      padding: 0 4px 0 0;
      max-height: calc(100vh - 288px);
      overflow: auto;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .project {
      padding: 0;
      &__header {
        & > *:not(:first-of-type) {
          display: none;
        }
      }
      &__item {
        flex-flow: column;
        align-items: flex-start;
        &-info ~ * {
          margin-top: 8px;
        }
      }
    }
  }
`;
