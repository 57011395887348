import React from 'react';

import styled from '@emotion/styled';
import Avatar from './Avatar';
import Badge from './Badge';
import { UserRoles, TeamMember } from './usermanagement.d';

const UserOption: React.FC<{ data: TeamMember }> = ({
  data: { avatar, name, email, role },
  ...props
}) => (
  <StyledUserOption {...props} className="user-option truncate">
    <Avatar src={avatar} className="user-option__photo" />
    <span className="user-option__name truncate">{name || email}</span>
    &nbsp;
    {role !== 'user' && <Badge>{UserRoles[role]}</Badge>}
  </StyledUserOption>
);

export default React.memo(UserOption);

const StyledUserOption = styled.span`
  display: inline-flex;
  align-items: center;
  .user-option {
    &__photo {
      margin: 0 12px 0 4px;
      flex-shrink: 0;
    }
    & > span {
      margin-left: 8px;
      text-transform: capitalize;
    }
  }
`;
