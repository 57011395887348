import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Icon, Button } from '@xchange/uikit';
import { TeamMember, UserProject } from './usermanagement.d';

interface UserDetailsProps {
  data: TeamMember;
  userProjects?: UserProject[];
  onSeeMoreClick?: (data: TeamMember) => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({ data, userProjects, onSeeMoreClick }) => {
  const { email, phone, officePhone } = data;
  const userProjectsShort = userProjects?.slice(0, 3) || [];
  const showProjects = userProjectsShort.length > 0;

  return (
    <StyledUserDetails className="users-details">
      <div className="user-details__item user-details__header">
        <div className="user-details__item-info user-details__item__contact">Contact Info</div>
        {showProjects && (
          <>
            <div className="user-details__item-info user-details__item__project">
              Recent Projects
            </div>
            <div className="user-details__item-info user-details__item__type">Type</div>
            <div className="user-details__item-info user-details__item__status">Status</div>
          </>
        )}
      </div>
      <div className="user-details__item">
        <div className="user-details__item-info user-details__item__contact">
          <a href={`mailto:${email}`} className="user-details__info user-details__link">
            <Icon name="email" />
            {email}
          </a>
          {officePhone && (
            <a href={`tel:${officePhone}`} className="user-details__info user-details__link">
              <Icon name="phone" />
              {officePhone}
            </a>
          )}
          {phone && (
            <a href={`tel:${phone}`} className="user-details__info user-details__link">
              <Icon name="smartphone" />
              {phone}
            </a>
          )}
        </div>
        {showProjects && (
          <div className="user-details__item-info user-details__item__projects">
            {userProjectsShort?.map(project => (
              <React.Fragment key={project.id}>
                <div className="user-details__item__project">{project.name}</div>
                <div className="user-details__item__type">{project.type}</div>
                <div
                  className={clsx('user-details__item__status', {
                    active: project.status?.toLocaleLowerCase() === 'active'
                  })}>
                  {project.status}
                </div>
              </React.Fragment>
            ))}
            <Button secondary onClick={() => onSeeMoreClick?.(data)} className="see-more-btn">
              See more
            </Button>
          </div>
        )}
      </div>
    </StyledUserDetails>
  );
};

export default React.memo(UserDetails);

const StyledUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  margin: 8px 0 0 60px;

  .user-details {
    &__header {
      color: ${props => props.theme.colors.grayDark};
    }
    &__item {
      display: flex;
      width: 100%;
      padding: 6px 0;
    }
    &__item__contact {
      flex: 2.5;
      margin-right: -60px;
    }
    &__item__project {
      flex: 1.5;
    }
    &__item__projects {
      flex: 4.5;
      display: grid;
      grid-template-columns: 1.5fr 1fr 2fr;
      grid-row-gap: 12px;
    }
    &__item__type {
      flex: 1;
    }
    &__item__status {
      flex: 2;
      &.active {
        color: ${props => props.theme.colors.lightGreen};
      }
    }
    &__info {
      display: flex;
      align-items: center;
      & + .user-details__info {
        margin-top: 12px;
      }
    }
    &__link {
      color: inherit;
      text-decoration: none;
      .icon {
        margin-right: 12px;
      }
    }
    &__status {
      color: ${props => props.theme.colors.lightGreen};
    }
  }
  .see-more-btn {
    width: 75px;
  }
`;
