import React, { useState, useContext } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styled from '@emotion/styled';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getCompanyInfo, updateCompanyInfo } from 'api/company-info';
import MainLayout from 'components/MainLayout';
import Button from 'components/Button';
import Search from 'components/Search';
import { DefaultCommissionsForm } from 'components/forms';
import ManageTeams from './ManageTeams';
import ManageUser from './ManageUser';
import Modal from 'components/Modal';
import InviteUser from './InviteUser';
import TeamFilter from './TeamFilter';
import UsersList from './UsersList';
import Context, { UserManagementProvider } from './UserManagementContext';
import { AgentTabKeys } from './usermanagement.d';

const UserManagementPage = () => {
  const [openManageModal, setOpenManageModal] = useState<boolean>(false);
  const [openCommissionsForm, setOpenCommissionsForm] = useState<boolean>(false);
  const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
  const [openTab, setOpenTab] = useState<AgentTabKeys>();
  const [commissionsData, setCommissionsData] = useState<CommissionsData>();
  const { userModalData, setUserModalData, filters, setFilters, reload } = useContext(Context);

  const [handleSearchInputChange] = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilters({ search: e.target.value }, 'replaceIn');
    },
    200
  );

  const setDefaultCommissions = async (values: CommissionsData) => {
    try {
      await updateCompanyInfo(values);
      setOpenCommissionsForm(false);
    } catch (err) {
      const firstErrorMessage = tryGetFirstError(err);
      notify(firstErrorMessage);
    }
  };

  const getDefaultCommissions = async () => {
    const companyInfo = await getCompanyInfo();

    return {
      sponsoring_broker_fee: companyInfo.sponsoring_broker_fee,
      technology_fee: companyInfo.technology_fee,
      desk_fee: companyInfo.desk_fee,
      sell_agreements_fee: companyInfo.sell_agreements_fee,
      lease_agreements_fee: companyInfo.lease_agreements_fee,
      transaction_coordination_fee: companyInfo.transaction_coordination_fee,
      risk_management_fee: companyInfo.risk_management_fee
    };
  };

  const handleOpenCommissionsForm = async () => {
    try {
      const commissionsData = await getDefaultCommissions();
      setCommissionsData(commissionsData);
      setOpenCommissionsForm(true);
    } catch (err) {
      const firstErrorMessage = tryGetFirstError(err);
      notify(firstErrorMessage);
    }
  };

  return (
    <MainLayout>
      <StyledUserManagementPage className="user-management">
        <div className="user-management__header">
          <h4 className="user-management__title">Team Management</h4>
          <Button className="user-management__invite-btn" onClick={() => setOpenInviteUser(true)}>
            Invite User
          </Button>
        </div>
        <Search defaultValue={filters.search} onChange={handleSearchInputChange} />
        <div className="team-controls">
          <TeamFilter />
          <Button
            className="user-management__manage-btn"
            onClick={handleOpenCommissionsForm}
            secondary>
            Set Default Commissions
          </Button>
          <Button
            className="user-management__manage-btn"
            onClick={() => setOpenManageModal(true)}
            secondary>
            Manage Teams
          </Button>
        </div>
        <UsersList />
        <ManageTeams open={openManageModal} onClose={() => setOpenManageModal(false)} />
        <ManageUser
          open={Boolean(userModalData)}
          onClose={() => {
            setUserModalData(undefined);
            setOpenTab(undefined);
          }}
          defaultTab={openTab}
        />
        <InviteUser
          open={openInviteUser}
          onClose={() => {
            setOpenInviteUser(false);
            reload();
          }}
          setOpenTab={setOpenTab}
          loadCommissionsInfo={getDefaultCommissions}
        />
        <Modal
          open={openCommissionsForm}
          modalTitle="Default Commissions and Fees"
          onClose={() => setOpenCommissionsForm(false)}
        >
          <DefaultCommissionsForm
            data={commissionsData}
            onSubmit={setDefaultCommissions}
          />
        </Modal>
      </StyledUserManagementPage>
    </MainLayout>
  );
};

const StyledUserManagementPage = styled.div`
  display: flex;
  flex-direction: column;
  .team-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    align-items: baseline;
  }
  .user-management {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
    }
    &__invite-btn {
      min-width: 100px;
    }
    &__manage-btn {
      min-width: 110px;
      &:first-of-type {
        margin-left: auto;
        margin-right: 16px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .team-controls {
      overflow-x: auto;
      width: 1145px;
      padding-right: 10px;
    }
    .user-management {
      &__header {
        margin: 0 0 16px;
      }
      &__manage-btn {
        margin-left: 16px;
      }
    }
  }
`;

export default props => (
  <UserManagementProvider>
    <UserManagementPage {...props} />
  </UserManagementProvider>
);
