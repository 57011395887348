import queryString from 'query-string';
import { snakeCase } from 'lodash';
import { fetchWithToken } from 'utils/fetch';
import { checkSuccess, urlEncodeBody } from 'utils/requests';
import { mapUsers, mapUser, mapTeams, mapProjects, mapCommissionsAndFees } from './mapping';
import { EmploymentContract, Team, TeamMember, UserProject } from './usermanagement.d';

export async function getUsers(): Promise<TeamMember[]> {
  const response = await fetchWithToken('/users');
  const data = await response.json();

  checkSuccess(data);
  return data;
}

export async function getUserProfileData(
  id: number
): Promise<{
  user: TeamMember;
  contracts: EmploymentContract[];
  commissions: Partial<CommissionsData>;
}> {
  const response = await fetchWithToken(`/users/${id}/profile`);
  const data = await response.json();

  checkSuccess(data);

  const { user, contracts, commissions } = mapUserProfile(data);

  return { user, contracts, commissions };
}

export async function getUsersDetailed(): Promise<TeamMember[]> {
  const response = await fetchWithToken('/users/detailed');
  const data = await response.json();

  checkSuccess(data);
  return mapUsers(data);
}

export async function getUserProjects(userId: number): Promise<UserProject[]> {
  const query = queryString.stringify({
    user_id: userId
  });
  const response = await fetchWithToken(`/listings?${query}`);
  const data = await response.json();

  // checkSuccess(data);
  return mapProjects(data.listings);
}

export async function getTeams(): Promise<Team[]> {
  const response = await fetchWithToken('/teams');
  const data = await response.json();

  checkSuccess(data);
  return mapTeams(data);
}

export async function createTeam(payload: TeamPayload): Promise<Team> {
  const formData = {
    name: payload.name,
    members: payload.members
  };

  const response = await fetchWithToken('/teams', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return data;
}

export async function deleteTeam(id: number): Promise<void> {
  await fetchWithToken(`/teams/${id}`, {
    method: 'DELETE'
  });
}

export async function promoteTeamMember(payload: EditTeamMemberPayload): Promise<TeamMember> {
  const formData = {
    email: payload.email,
    team_id: payload.teamId,
    role_id: payload.roleId,
    active: payload.active ? 1 : 0
  };

  const response = await fetchWithToken('/users/promote', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return mapUser(data);
}

export async function updateUserProfileData(
  payload: Partial<TeamMember>
): Promise<{
  user: TeamMember;
  contracts: EmploymentContract[];
  commissions: Partial<CommissionsData>;
}> {
  const { id, ...rest } = payload;
  const nonEmptyFields: Partial<TeamMember> = {};

  Object.entries(rest).forEach(([key, value]) => {
    if (value !== undefined)
      nonEmptyFields[snakeCase(key)] = typeof value === 'boolean' ? Number(value) : value;
  });

  const response = await fetchWithToken(`/users/${id}/profile`, {
    method: 'POST',
    body: urlEncodeBody(nonEmptyFields)
  });

  const data = await response.json();

  checkSuccess(data);

  const { user, contracts, commissions } = mapUserProfile(data);

  return { user, contracts, commissions };
}

export async function editTeamMember(payload: EditTeamMemberPayload): Promise<TeamMember> {
  const formData = {
    email: payload.email,
    team_id: payload.teamId
  };

  const response = await fetchWithToken('/users/promote', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return mapUser(data);
}

export async function deleteUser(id: number): Promise<any> {
  await fetchWithToken(`/users/${id}`, {
    method: 'DELETE'
  });
}

export async function inviteUser(
  payload: InviteUserPayload
): Promise<{
  signUrl: string;
}> {
  const { has_signed_contract, ...formValues } = payload;
  const formData = {
    has_signed_contract: payload.has_signed_contract ? 1 : 0,
    ...formValues
  };

  const response = await fetchWithToken('users/invite', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);

  return {
    signUrl: data.signing_url
  };
}

const mapUserProfile = (data: { [key: string]: any }) => {
  const user = mapUser(data);
  const commissions = mapCommissionsAndFees(data as CompanyData);
  const contracts =
    data.documents?.map(item => ({
      id: item.id,
      filename: item.filename,
      mimeType: item.mime_type,
      createdOn: item.created_on,
      isDraft: Boolean(item.is_draft),
      link: item.link
    })) || [];

  return { user, commissions, contracts };
};

type InviteUserPayload = {
  email: string;
  name: string;
  team_id: number | null;
  phone: string;
  position: string;
  has_signed_contract: boolean;
} & Partial<CommissionsData>;

export type TeamPayload = {
  name: string;
  members?: number[];
};

export type EditTeamMemberPayload = {
  email?: string;
  teamId?: number | null;
  roleId?: number | null;
  active?: boolean;
};
