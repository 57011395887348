/* eslint-disable camelcase */
export type TeamMember = {
  id: number;
  email: string;
  name?: string;
  teamId: number | null;
  team?: {
    id: number;
    name: string;
    description: string;
    active: boolean;
  };
  roleId: number;
  phone?: string;
  officePhone?: string;
  avatar?: string;
  role: string;
  activeTransactions?: number;
  closedTransactions?: number;
  avatar?: string;
  closedTransactions: number;
  activeTransactions: number;
  active: boolean;
  showOnOurTeam?: boolean;
  commissionPercentage?: number;
  commissionFixedFee?: number;
  contractDocumentId?: number;
  position?: string;
  documents?: EmploymentContract[];
};

export type Team = {
  id: number;
  name: string;
  description: string;
  active: string;
  listingsCount: number;
  membersCount: number;
  members: TeamMember[];
  avatar?: string;
};

export type UserProject = {
  id: number;
  userId: number;
  name: string;
  type: string;
  status: string;
  url: string | undefined;
};

export type EmploymentContract = {
  id: number;
  filename: string;
  mimeType: string;
  createdOn: number;
  isDraft: boolean;
  link: string;
};

export enum UserRoles {
  managing_broker = 'Managing Broker',
  admin = 'Admin',
  team_lead = 'Team Lead',
  user = 'User'
}

export enum UserRoleTypes {
  managingBroker = 1,
  admin = 2,
  teamLead = 3,
  user = 4
}

export enum AgentTabKeys {
  Projects = 'projects',
  Contracts = 'contracts'
}
