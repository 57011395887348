import React, { useContext, useState, useMemo } from 'react';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Modal, { ModalProps } from 'components/Modal';
import Tag from 'components/Tag';
import Context from './state';
import UserManagementContext from '../UserManagementContext';

type FormState = {
  id?: number;
  members: number[];
};

const initialFormState: FormState = {
  members: []
};

const CreateTeamModal: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const { addTeam, getUserOptions } = useContext(Context);
  const { users } = useContext(UserManagementContext);
  const [values, setValues] = useState<FormState>(initialFormState);
  const { id, members } = values;

  const unassignedUsers = useMemo(() => users.filter(user => !user.teamId), [users]);
  const teamLeadOptions = useMemo(() => getUserOptions(unassignedUsers), [unassignedUsers]);
  const userOptions = useMemo(
    () => getUserOptions(unassignedUsers.filter(user => user.id !== id)),
    [unassignedUsers, id]
  );

  const clearForm = () => setValues(initialFormState);

  const handleConfirm = () => {
    if (!id) return;
    addTeam({ id, members });
    handleClose();
  };

  const handleClose = () => {
    onClose?.();
    clearForm();
  };

  const membersCount = members.length;
  const displayText = useMemo(() => {
    return membersCount > 1
      ? `${membersCount} members`
      : membersCount
      ? userOptions.find(option => option.value === members[0])?.text
      : '';
  }, [membersCount, userOptions]);

  return (
    <StyledTeamModal
      className="team-modal"
      onClose={handleClose}
      modalTitle="Create Team"
      {...props}>
      <Dropdown
        label="Team Lead"
        placeholder="Select"
        options={teamLeadOptions}
        value={id}
        search
        onChange={value => setValues(v => ({ ...v, id: value }))}
      />

      <Dropdown
        label="Team Members"
        placeholder="Select"
        options={userOptions}
        value={members}
        text={displayText}
        multiple
        search={userOptions.length > 0}
        onChange={value => setValues(v => ({ ...v, members: value }))}
      />
      <div className="user-tags">
        {members.map(item => (
          <Tag
            key={item}
            onDelete={() => setValues(v => ({ ...v, members: v.members.filter(v => v !== item) }))}>
            {userOptions.find((user: DropdownOption) => user.value === item)?.text}
          </Tag>
        ))}
      </div>
      <Button className="create-team-btn" onClick={handleConfirm} disabled={!id}>
        Create
      </Button>
    </StyledTeamModal>
  );
};

export default React.memo(CreateTeamModal);

const StyledTeamModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }
  .create-team-btn {
    width: 100%;
  }
  .dropdown {
    margin: 0 0 16px;
  }
  .user-tags {
    margin: 0 0 8px;
    display: flex;
    flex-flow: wrap;

    & > * {
      margin: 0 8px 8px 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
