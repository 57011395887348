import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import notify from 'notify';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal, { ModalProps } from 'components/Modal';
import Search from 'components/Search';
import ManageTableItem from './ManageTableItem';
import TeamMember from './TeamMember';
import UserManagementContext from '../UserManagementContext';
import Context from './state';
import { TeamMember as TeamMemberType } from '../usermanagement.d';

interface ManageTeamsModalProps extends ModalProps {
  setOpenTeamModal: (value: boolean) => void;
}

const errorNotify = () =>
  notify(
    <>
      <Icon name="info" />
      &nbsp;&nbsp; All users are in teams. Please remove someone from the team first.{' '}
    </>,
    {
      type: 'error'
    }
  );

const ManageTeamsModal: React.FC<ManageTeamsModalProps> = ({ setOpenTeamModal, ...props }) => {
  const { teams, users } = useContext(UserManagementContext);
  const {
    setOpenTeamMemberModal,
    setDeleteTeamId,
    setDeleteTeamMember,
    search,
    setSearch
  } = useContext(Context);

  const teamMembers = (teamId: number) => users.filter(user => user.teamId === teamId);
  const unassignedUsersCount = useMemo(() => users.filter(user => !user.teamId).length, [users]);

  const filteredTeams = useMemo(() => {
    if (!search) return teams;
    return teams?.filter(team => team.name.toLowerCase().includes(search.toLocaleLowerCase()));
  }, [search, teams]);

  const handleAddTeamMemberClick = (teamId: number) => {
    if (unassignedUsersCount) {
      return setOpenTeamMemberModal(teamId);
    }
    errorNotify();
  };

  const handleCreateTeamClick = () => {
    if (unassignedUsersCount) {
      return setOpenTeamModal(true);
    }
    errorNotify();
  };

  return (
    <StyledManageTeamsModal className="manage-modal" modalTitle="Manage Teams" {...props}>
      <div className="team-modal-header">
        <Search value={search} onChange={e => setSearch(e.target.value)} />
        <StyledButton className="create-team" onClick={handleCreateTeamClick}>
          Create Team
        </StyledButton>
      </div>
      <div className="manage-table">
        <div className="manage-table-header">
          <span className="manage-table-header__item manage-table-header__team">
            {' '}
            <span className="team-name">Team</span>
          </span>
          <span className="manage-table-header__item manage-table-header__members">Members</span>
          <span className="manage-table-header__item manage-table-header__listings">Listings</span>
        </div>
        {filteredTeams?.map(team => (
          <StyledAccordion
            label={<ManageTableItem data={team} onRemove={setDeleteTeamId} />}
            key={team.id}>
            {teamMembers(team.id).map((member: TeamMemberType) => (
              <TeamMember data={member} onRemove={setDeleteTeamMember} key={member.id} />
            ))}
            <StyledAddButton
              className="add-button"
              simple
              onClick={() => handleAddTeamMemberClick(team.id)}>
              + Add New Member
            </StyledAddButton>
          </StyledAccordion>
        ))}
      </div>
    </StyledManageTeamsModal>
  );
};

const StyledManageTeamsModal = styled(Modal)`
  .modal-content {
    min-width: 728px;
    padding: 12px;
    overflow: auto;
  }

  .team-modal-header {
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
  }
  .manage-table-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 0 12px;
    &__item {
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      flex: 1;
    }
    &__team {
      flex: 2.5;
      margin-left: 21px;
      .team-name {
        margin-left: -21px;
      }
    }
    &__listings {
      margin-right: 81px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .modal-content {
      min-width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .modal-bg {
      padding: 0;
    }
    .modal-body {
      position: relative;
    }
    .modal-content {
      height: 100%;
      top: 0;
      left: 0;
      padding: 10px 10px 52px;
    }
    .manage-table-header {
      display: none;
    }
    .team-modal-header {
      margin: 0;
      .create-team {
        margin-left: 35px;
      }
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  .label {
    width: 100%;
  }
  .counter {
    display: none;
  }
  .accordion-content {
    margin: 0 0 16px 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .accordion-head {
      align-items: flex-start;
      padding: 16px 0;
      .icon {
        margin-top: 7px;
      }
    }
  }
`;
const StyledAddButton = styled(Button)`
  &.simple {
    color: ${props => props.theme.colors.red};
    font-weight: normal;
  }
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

export default React.memo(ManageTeamsModal);
