import keysToCamel from 'utils/keysToCamel';
import { SITE_URL } from 'consts';
import { Team, TeamMember, UserProject } from './usermanagement.d';

// renames keys to camelCase and add team name
export const mapUser = (item): TeamMember => {
  return keysToCamel(item);
};

// map users
export const mapUsers = (users): TeamMember[] => {
  return users.map(mapUser);
};

// renames keys to camelCase and add members
export const mapTeams = (teams): Team[] => {
  return teams.map(keysToCamel);
};

// map user listings
export const mapProjects = (listings): UserProject[] => {
  return listings.map(listing => ({
    id: listing.id,
    name: listing.address1,
    status: listing.status,
    type: listing.type,
    url:
      SITE_URL && listing.address1
        ? `${SITE_URL}/listing/${listing.id}/`
        : undefined
  }));
};

export const mapCommissionsAndFees = (data: CompanyData): Partial<CommissionsData> => {
  return {
    sponsoring_broker_fee: data.sponsoring_broker_fee,
    technology_fee: data.technology_fee,
    desk_fee: data.desk_fee,
    transaction_coordination_fee: data.transaction_coordination_fee,
    risk_management_fee: data.risk_management_fee,
    sell_agreements_fee: data.sell_agreements_fee,
    lease_agreements_fee: data.lease_agreements_fee
  };
};
