import React from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Avatar from '../Avatar';

interface ManageTableItemProps {
  data: Team;
  onRemove: (value: number) => void;
}

const ManageTableItem: React.FC<ManageTableItemProps> = ({ data, onRemove }) => {
  const layout = useLayout();
  const { name, avatar, membersCount, listingsCount } = data;

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemove(data.id);
  };

  switch (layout) {
    case 'mobile':
      return (
        <StyledManageTableItem className="manage-table-item">
          <div className="manage-table-item-info">
            <span className="manage-table-item-info__item manage-table-item-info__team">
              <Avatar src={avatar} className="manage-table-item-info__team-img" />
              {name}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__members">
              <span className="label">Members</span> {membersCount || '-'}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__listings">
              <span className="label">Listings</span> {listingsCount || '-'}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__manage-button">
              <StyledButton className="manage-button" secondary onClick={handleRemove}>
                ✗ Remove
              </StyledButton>
            </span>
          </div>
        </StyledManageTableItem>
      );

    case 'desktop':
    case 'tablet':
      return (
        <StyledManageTableItem className="manage-table-item">
          <div className="manage-table-item-info">
            <span className="manage-table-item-info__item manage-table-item-info__team">
              <Avatar src={avatar} className="manage-table-item-info__team-img" />
              {name}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__members">
              {membersCount || '-'}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__listings">
              {listingsCount || '-'}
            </span>
            <span className="manage-table-item-info__item manage-table-item-info__manage-button">
              <StyledButton className="manage-button" secondary onClick={handleRemove}>
                ✗ Remove
              </StyledButton>
            </span>
          </div>
        </StyledManageTableItem>
      );
    default:
      return null;
  }
};

const StyledManageTableItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};
  font-weight: normal;
  &:last-of-type {
    border-bottom: none;
  }
  .manage-table-item-info {
    display: flex;
    flex: 1;
    &__item {
      flex: 1;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      display: flex;
    }
    &__team {
      flex: 2.5;
    }
    &__manage-button {
      flex-basis: 81px;
      flex-grow: 0;
    }
    &__team-img {
      margin: 0 12px 0 0;
      flex-shrink: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .manage-table-item-info {
      flex-direction: column;
      .label {
        width: 100px;
        margin: 0 10px 0 0;
      }
      &__item {
        margin: 16px 0 0;
        &:first-of-type {
          margin: 0;
        }
      }
      &__manage-button {
        flex-basis: auto;
        margin: 16px 0 0;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  height: 24px;
`;

export default ManageTableItem;
